import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageLayout from '../components/Layout/PageLayout';
import GenericError from '../components/GenericError';
import { createGlobalStyle } from 'styled-components';
import media from '../helpers/media';
import { OverrideGlobalStyle } from '../styles/GlobalStyles';

const maxattempts = () => {
  return (
    <>
      <OverrideGlobalStyle />
      <Header />
      <GenericError />
    </>
  );
};

export default maxattempts;
