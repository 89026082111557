import { createGlobalStyle } from 'styled-components';
import media from '../helpers/media';

export const OverrideGlobalStyle = createGlobalStyle`
  html {
    background-color: white;
    background-image: none;
  }
  ${media.sm`
    div[class*='ModalContainer'] > div {
      width: 450px;
      > div > div {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  `}
  ${media.md`    
    div[class*='ModalContainer'] > div {
      width: 460px;
      > div > div {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  `}
`;