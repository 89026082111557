import React from 'react';
import { Text, Button, Grid, Row, Column } from '@dls/web';
import styled from 'styled-components';
import { flowConfigState } from '../..';
import { useRecoilValue } from 'recoil';
import { navigate } from 'gatsby';

const StyledButtonRow = styled(Row)`
  pading-top: 24px;
`;

export const StyledMainRow = styled(Row)`
  padding-top: 5rem;
`;

const GenericError = () => {
  const flowConfig = useRecoilValue(flowConfigState);
  const flowId = flowConfig?.flowId;

  console.log('flowId', flowId);

  return (
    <Grid>
      <StyledMainRow center={'xs'}>
        <Column>
          <Text type={'pageTitle'}>We've received too many attempts</Text>
        </Column>
      </StyledMainRow>
      <Row center={'xs'}>
        <Column>
          <Text type={'body'}>Try again later.</Text>
        </Column>
      </Row>
      <StyledButtonRow center={'xs'}>
        <Column>
          <Button
            secondary
            onClick={() => {
              navigate(`/login?flowId=${flowId}`);
            }}
          >
            Back to Login
          </Button>
        </Column>
      </StyledButtonRow>
    </Grid>
  );
};

export default GenericError;
